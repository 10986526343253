$oprx-color-primary: #382e4c;
$oprx-color-primary-opacity: #c3c0c9;
$oprx-color-secondary: #78a240;
$oprx-color-secondary-hover: #689131;
$oprx-color-text-primary: #2d2d2d;
$oprx-color-text-secondary: #797977;
$oprx-color-shadow: #6a6a6a;

$oprx-font-size-text-default: 14px;

$oprx-shadow-card: 0 0 15px $oprx-color-shadow;

$oprx-font-weight-light: 300;
$oprx-font-weight-normal: 400;
$oprx-font-weight-semibold: 600;
$oprx-font-weight-bold: 700;
$oprx-font-weight-bold-extra: 800;
