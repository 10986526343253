.bar {
  padding: 0 15px;
  margin-bottom: 30px;
  text-align: right;
}

.navlink {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
}
