.inputGroup {
  margin-bottom: 15px;
}

.passwordGroup {
  margin-bottom: 25px;
}

.passwordGroupIcon {
  transform: rotate(-45deg);
}
