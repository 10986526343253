@import 'bootstrap';
@import 'variables';

html {
  overflow-y: auto;
  scroll-behavior: smooth;
}

html, body {
  background: $oprx-color-secondary;
}

html, body, button, input, select, textarea {
  font-family: 'Open Sans', arial, sans-serif;
  font-size: $oprx-font-size-text-default;
}

html, body, #root, .oprx-page-container {
  width: 100%;
  height: 100%;
}

#root {
  overflow: hidden;
}

body {
  font-weight: $oprx-font-weight-normal;
}

.modal-header .btn-close:focus,
.modal-header .btn-close:active {
  outline: none !important;
  box-shadow: none;
}

.oprx-btn-loader-text,
.oprx-btn-text {
  margin-left: 5px;
}

.oprx-bs-alert-container {
  position: fixed;
  top: 0;
  width: 100%;

  .alert {
    border-radius: 0;
  }

  .btn-close:focus,
  .btn-close:active {
    outline: none !important;
    box-shadow: none;
  }
}

.oprx-centered-card {
  width: 400px;
  margin: 0 auto;
  padding: 15px;
  box-shadow: $oprx-shadow-card;

  .oprx-centered-btn {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}

.oprx-vertical-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.oprx-form-group {
  position: relative;
  margin-bottom: 15px;

  .form-label {
    font-weight: 600;
    margin-bottom: 5px;
  }

  &.is-inline {
    display: flex;
    flex-direction: row;

    .form-label {
      margin: 10px 10px 0 0;
      white-space: nowrap;
    }
  }
}

td.oprx-success {
  background-color: #d1e7dd !important;
  color: #0f5132 !important;
}

td.oprx-warning {
  background-color: #fff3cd !important;
  color: #664d03 !important;
}

td.oprx-error {
  background-color: #f8d7da !important;
  color: #842029 !important;
}
